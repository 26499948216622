<template>
  <section class="pb-8">
    <div class="container">
      <div class="order-bg mb-3 mb-md-8 success">
        登入中請稍後...
      </div>
    </div>
  </section>
</template>

<script>
import { LineAuth } from '@/api/LoginApi';

export default {
  data() {
    return {
    };
  },
  created() {
    this.LineAuth();
  },
  methods: {
    LineAuth() {
      const { authToken } = this.$route.query;
      // authToken=登入token,要用此值換取登入token
      // socialNetwork=登入類型 //1:LINE
      // errorNumber=登入狀態,0:成功，非0失敗要轉到login頁面並顯示登入失敗
      LineAuth({
        socialNetwork: 1, // 1:LINE
        authToken,
        errorNumber: 0,
      }).then((response) => {
        if (response.data.statusCode === 0) {
          const { jwtToken } = response.data.data;
          const exp = JSON.parse(
            decodeURIComponent(
              escape(
                window.atob(
                  jwtToken
                    .split('.')[1]
                    .replace(/-/g, '+')
                    .replace(/_/g, '/'),
                ),
              ),
            ),
          ).ExpTime;
          const d = this.$moment(exp).add(30, 'days');
          const startDate = this.$moment();
          const endDate = this.$moment(d);
          const seconds = endDate.diff(startDate, 'seconds');
          document.cookie = `token=${jwtToken}; expires=${d}; max-age=${seconds}; path=/`;
          this.$message({
            showClose: true,
            type: 'success',
            message: response.data.message,
          });
          this.$setUserAccount();
          this.$bus.$emit('getShoppingCartCount');
          const { redirect } = this.$route.query;
          if (redirect) this.$router.replace(redirect);
          else this.$router.replace('/');
        } else this.$router.push('/Login');
      });
    },
  },
};
</script>

<style></style>
